<template>
    <section>
        <div class="category">
            <h3>Select Product</h3>
            <div class="row category__tile-container">
                <div class="col-4" v-for="product in products" :key="product.name">
                    <button @click="getSelectedProduct(product)">
                        <div class="category__tile">
                            <img :src="product.img" alt="icon" />
                            <h4>{{ product.value }}</h4>
                        </div>
                    </button>
                </div>
            </div>
        </div>

        <div class="category">
            <h3>Select Report</h3>
            <div class="row category__tile-container">
                <div class="col-4" v-for="report in reports" :key="report.name">
                    <button @click="viewReport(report.path)">
                        <div class="category__tile">
                            <h4>{{ report.name }}</h4>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            reports: [{ name: '75 Day Supply View', path: '/reporting' }],
        };
    },
    computed: {
        ...mapGetters(['isAdmin']),
        ...mapGetters({ products: 'getProducts' }),
    },
    methods: {
        getSelectedProduct(product) {
            this.$store.commit('setProduct', product);
            this.$router.push('/planning/suggested');
        },
        viewReport(path) {
            this.$router.push(path);
        },
    },
};
</script>
